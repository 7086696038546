<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="color1 color1Text--text"
        fab small
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Tournament Quick Add</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div class="title">Enter the tournament id(s)</div>
        <v-text-field
          label="Id"
          color="color3"
          v-model="ids"
        ></v-text-field>
      </v-card-text>
      <v-card-text class="text-end">
        <v-btn
          color="success white--text"
          class="mr-2"
          small fab
          :disabled="!valid"
          :loading="loading"
          @click.stop="doTheAdd"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
        <v-btn
          color="error white--text"
          small fab
          :disabled="loading"
          @click.stop="dialog=false"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['ratingSystem'],
  data () {
    return {
      dialog: false,
      ids: null,
      loading: null
    }
  },
  computed: {
    parsedIds () {
      return this.ids && this.ids.split(',').map(m => +m).filter(f => !isNaN(f))
    },
    dto () {
      return this.ids && { add: this.parsedIds }
    },
    valid () {
      return this.parsedIds && this.parsedIds.length > 0
    }
  },
  methods: {
    doTheAdd () {
      this.loading = true
      this.$VBL.ratings.systemOrganizations.tournaments.post(this.ratingSystem.id, 'quickadd', this.dto)
        .then(r => {
          this.$emit('events-added')
          this.dialog = false
        })
        .catch((error) => { console.log(error) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function () {
      this.ids = null
    }
  }
}
</script>
