<template>
  <v-card :color="disabled ? 'grey white--text' : 'white'" tile>
    <v-overlay
      :value="!!disabled"
      absolute
    ></v-overlay>
    <div class="text-center text-h6 py-2">
      <v-avatar
        size="64"
        v-if="!rating.rating.startsWith('U')"
      >
        <img :src="getBasgeSrc(rating.rating)" :alt="rating.rating">
      </v-avatar>
      <div v-else class="d-flex justify-center align-center" style="min-height: 64px;">{{rating.rating}}</div>
    </div>
    <v-data-table
      v-if="rating && false"
      :headers="headers"
      :items="rating.earningRows"
      dense hide-default-footer
      :items-per-page="-1"
      disable-sort
      style="border-radius: 0 !important"
    >
      <template v-slot:[`item.finish`]="{item}">
        <div class="text-center">
          {{item.finish | ordinal}}
        </div>
      </template>
    </v-data-table>
    <v-simple-table
      v-if="rating"
      dense
      style="border-radius: 0 !important"
    >
      <template v-slot:default>
        <thead>
          <tr class="grey lighten-2">
            <th class="text-end" width="125px">
              Number of Teams:
            </th>
            <th
              v-for="h in headers"
              :key="h.text"
              class="text-center"
              width="50"
            >
              {{h.text}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in rating.earningRows"
            :key="item.finish"
          >
            <td class="text-center">{{ item.finish | ordinal }}</td>
            <td
              v-for="(h, i) in headers"
              :key="h.text"
              class="text-center pa-0"
              :style="getTdStyle(item[h.value], i, headers)"
              width="50"
            >
              <div
              >
                {{ item[h.value] }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import BadgeSrc from '@/classes/BadgeSrc'

export default {
  props: ['rating', 'disabled', 'teams'],
  computed: {
    headers () {
      return this.rating.headers || []
    }
  },
  methods: {
    getBasgeSrc (rating) {
      return BadgeSrc(rating)
    },
    getTdStyle (rating, i, headers) {
      if (!rating) return null
      const border = 'border-color: #fff !important; '
      const borderLeft = i === 0 ? '' : 'border-left: thin solid white;'
      const color = this.getColor(rating, headers, i)
      return `${border}${borderLeft}background-color: ${color}; color: white;`
    },
    getColor (rating, headers, i) {
      if (this.disabled) return 'grey'
      if (this.teams) {
        const min = headers[i].value
        const max = i < (headers.length - 1) ? headers[i + 1].value : 99999999
        const inRange = this.teams >= min && this.teams < max
        if (!inRange) return 'grey'
      }
      switch (rating) {
        case 'B':
          return '#0a71ce'
        case 'A':
          return '#68abe4'
        case 'AA':
          return '#3c97cb'
        case 'AAA':
          return '#0971ce'
      }
    }
  }
}
</script>
