<template>
  <v-card class="mb-1">
    <v-card-text class="pa-2">
      <div class="text-h5" v-if="summary.ratedAs">
          {{summary.division}} {{past ? 'was' : 'is'}} sanctioned as
          {{summary.ratedAs | ratingDisplay}}
      </div>
      <div class="caption" v-else>
          {{summary.division}} is unsanctioned
      </div>
      <div v-if="showEarnings">
        <earning-table
          :rating="ratingTable"
          :teams="summary.teams"
        ></earning-table>
      </div>
      <div v-if="summary.ratedAs">Reason: {{explanation}}</div>
      <div>
        <v-btn color="color3" text x-small @click.stop="detailed = !detailed">
          {{detailed ? 'hide' : 'show'}} details
        </v-btn>
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-card-text class="pa-2 pt-0 text-left" v-if="detailed">
        <div>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Total Teams</td>
                  <td>{{summary.teams}}</td>
                </tr>
                <tr
                  v-for="p in playerSummaries" :key="p.rating"
                  :class="!p.sanctioned ? 'grey--text' : ''"
                >
                  <td>Players rated {{p.rating | ratingDisplay}} or higher {{p.sanctioned}}</td>
                  <td>{{p.playerIdList.length}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import { ratingDisplay } from '@/Filters'
import { juniors } from '@/classes/VBLRatings.js'
import EarningTable from '@/components/Ratings/Explanation/EarningTable'

export default {
  props: ['summary', 'past', 'showEarnings'],
  data () {
    return {
      detailed: false
    }
  },
  computed: {
    ratingTable () {
      let r = this.summary.ratedAs.replace(/[0-9]/g, '')
      if (r.startsWith('U')) r = 'Unrated'
      return juniors.find(f => f.rating === r)
    },
    playerSummaries () {
      var result = []

      for (const prop in this.summary.ratingGroup) {
        if (!prop.includes('UR')) {
          const obj = {
            rating: prop,
            value: this.summary.ratingGroup[prop],
            playerIdList: [],
            teams: this.summary.teams,
            get sanctioned () {
              const t = this.teams
              const p = this.playerIdList.length
              return (t >= 12 && p >= 8) || (t >= 9 && p >= 10) || (p >= 12)
            },
            detailed: false
          }
          const v = this.summary.ratingGroup[prop]
          const x = this.summary.playerValues.find(f => f.ratingValue === v)
          if (x) {
            obj.playerIdList.push(...x.playerIdList)
            // const t = this.summary.teams
            // const p = obj.playerIdList.length
            // obj.sanctioned = (t >= 12 && p >= 8) || (t >= 9 && p >= 10) || (p >= 12)
          }
          result.push(obj)
        }
      }
      return result.sort(firstBy('value', -1))
    },
    sanctionedAs () {
      return this.playerSummaries.filter(f => f.sanctioned)[0]
    },
    explanation () {
      const s = this.summary
      if (s.trusted) return 'This event was approved as a "trusted event", all divisions were manually reviewed and assigned a rating by VBL staff'
      const sa = this.sanctionedAs
      if (!sa) {
        if (s.ratedAs.startsWith('B1')) {
          if (s.teams >= 8) {
            return 'Has 8 or more teams'
          } else {
            return 'Has 4 or more teams and at least 2 rated players'
          }
        }
        if (s.ratedAs.startsWith('UR1') && s.teams >= 4) {
          return 'Has 4 or more teams and less than 2 rated players'
        }
      }

      const t = s.teams
      const p = this.sanctionedAs ? this.sanctionedAs.playerIdList.length : 0
      return (t >= 12 && p >= 8) ? `Minimum of 12 teams with at least 8 players rated ${ratingDisplay(sa.rating)} or higher`
        : (t >= 9 && p >= 10) ? `Minimum of 9 teams with at least 10 players rated ${ratingDisplay(sa.rating)} or higher`
          : (p >= 12) ? `Minimum of 6 teams with at least 12 players rated ${ratingDisplay(sa.rating)} or higher`
            : '???'
    }
  },
  components: {
    EarningTable
  }
}
</script>
