<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="infoBtn"
        v-on="on"
        color="grey"
        icon x-small
        class="ml-3"
      >
        <v-icon small>fas fa-info-circle</v-icon>
      </v-btn>
      <v-btn
        v-else
        v-on="on"
        color="color3 color3Text--text"
        text small
      >
        Division Ratings
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Current Ratings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog= false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :class="{'pt-3': !hasMore}">
        <v-expand-transition>
          <div class="text-center" v-if="loading">
            <v-progress-circular indeterminate class="my-12" size="48"></v-progress-circular>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div class="text-center" v-if="!loading">
            <div class="text-end" v-if="hasMore">
              <v-btn color="color3" text x-small @click.stop="showAll = !showAll">
                {{showAll ? 'Hide Unsanctioned' : 'Show All'}}
              </v-btn>
            </div>
            <division-rating-card
              v-for="summary in summaries"
              :key="summary.id"
              :summary="summary"
              :past="past"
              :showEarnings="!ratingSystemId"
            >
            </division-rating-card>
          </div>
        </v-expand-transition>
        <v-alert type="info" text v-if="showRecalc">
          There seems to be a discrepancy in the team count. Click recalculate below, to update. {{sumTeams}} - {{tournamentTeams}}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="color3" text x-small @click.stop="getDivisionRatings(true)">
          Recalculate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DivisionRatingCard from './DivisionRatingCard'
import sum from 'lodash.sum'

export default {
  props: ['tournamentId', 'infoBtn', 'past', 'tournament', 'ratingSystemId'],
  data () {
    return {
      dialog: false,
      loading: false,
      divisionSummaries: null,
      showAll: false,
      detailed: true
    }
  },
  computed: {
    summaries () {
      return this.divisionSummaries ? this.showAll ? this.divisionSummaries : this.divisionSummaries.filter(f => f.ratedAs) : []
    },
    hasMore () {
      return this.divisionSummaries && this.divisionSummaries.length !== this.divisionSummaries.filter(f => f.ratedAs).length
    },
    sumTeams () {
      return this.divisionSummaries ? sum(this.divisionSummaries.map(m => m.teams)) : 0
    },
    tournamentTeams () {
      return this.tournament ? this.tournament.activeTeams.length : 0
    },
    showRecalc () {
      return this.tournament && this.tournament.activeTeams.length !== this.sumTeams
    }
  },
  methods: {
    getDivisionRatings (recalc) {
      this.loading = true
      this.$VBL.ratings.getDivisionRatings(this.tournamentId, false, recalc)
        .then(r => {
          this.divisionSummaries = r.data
          if (this.ratingSystemId) {
            this.divisionSummaries = this.divisionSummaries.filter(f => f.ratingSystemId === this.ratingSystemId)
          }
          this.$nextTick(() => {
            if (this.summaries.length === 0 && this.hasMore) {
              this.showAll = true
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPlayerSummary (item) {
      var result = []

      for (const prop in item.ratingGroup) {
        if (!prop.includes('UR')) {
          const obj = {
            rating: prop,
            playerIdList: [],
            sanctioned: false,
            get sanctioned2 () {
              const t = this.teams
              const p = this.playerIdList.length
              return (t >= 12 && p >= 8) || (t >= 9 && p >= 10) || (p >= 12)
            },
            detailed: false
          }
          const v = item.ratingGroup[prop]
          const x = item.playerValues.find(f => f.ratingValue === v)
          if (x) {
            obj.playerIdList.push(...x.playerIdList)
            const t = item.teams
            const p = obj.playerIdList.length
            obj.sanctioned = (t >= 12 && p >= 8) || (t >= 9 && p >= 10) || (p >= 12)
          }
          result.push(obj)
        }
      }
      return result
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        this.getDivisionRatings()
      }
    }
  },
  components: {
    DivisionRatingCard
  }
}
</script>
