<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text" dense flat v-if="!!ratingSystem">
      Tournament Results
      <v-spacer></v-spacer>
      <tournament-quick-add
        :ratingSystem="ratingSystem"
        @events-added="getTournaments"
      ></tournament-quick-add>
    </v-toolbar>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" sm="9" v-if="!!ratingSystem">
          <v-autocomplete
            :items="summaries"
            v-model="tournamentId"
            label="Select a Tournament"
            item-text="idNameAndDate"
            item-value="id"
            :loading="loading"
            outlined
            color="color3"
            item-color="color3"
            :disabled="loading"
            persistent-hint
            :hint="tournamentId ? `Tournament Id: ${tournamentId}` : ''"
          >
            <template v-slot:append-outer>
              <v-slide-x-transition mode='out-in'>
                <v-btn
                  color="color3"
                  text
                  :disabled="!tournamentId"
                  :loading="loading"
                  @click.stop="rerun(tournamentId)"
                >rerun</v-btn>
              </v-slide-x-transition>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <tournament-rating-summary-dialog
            v-if="tournamentId"
            :tournamentId="tournamentId"
            :ratingSystemId="ratingSystem ? ratingSystem.id : ratingSystemId"
          ></tournament-rating-summary-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <player-ratings-table
            :players="results"
            :loading="loading"
            :ratingSystem="ratingSystem"
          ></player-ratings-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Summary from '@/classes/TournamentSummary'
import PlayerRatingsTable from '@/components/Ratings/PlayerRatingsTable'
import PlayerRatings from '@/classes/PlayerRatings'
import TournamentQuickAdd from './TournamentQuickAdd'
import TournamentRatingSummaryDialog from './TournamentRatingSummaryDialog'

export default {
  props: ['ratingSystem', 'username', 'ratingSystemId', 'tourneyId'],
  data () {
    return {
      summaries: [],
      results: [],
      tournamentId: null,
      loading: false,
      page: {},
      search: null,
      dialog: false
    }
  },
  methods: {
    getTournaments () {
      this.loading = true
      // const query = `active=false&organizationUserName=${this.username}&withCounts=true&coOp=false`
      // this.$VBL.tournament.getAllSummaries(query)
      this.$VBL.ratings.systemOrganizations.tournaments.get(this.ratingSystem.id, 'ALL')
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getResults () {
      this.loading = true
      this.$VBL.ratings.getResults(this.tournamentId, (this.ratingSystemId || this.ratingSystem.id))
        .then(r => {
          this.results = r.data.map(m => new PlayerRatings(m))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rerun (tournamentId) {
      this.loading = true
      this.$VBL.ratings.rerun(tournamentId, this.ratingSystem.id)
        .then(r => {
          this.results = r.data.map(m => new PlayerRatings(m))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    tournamentId: 'getResults',
    'ratingSystem.id': 'getResults'
  },
  mounted () {
    if (this.ratingSystemId) {
      this.tournamentId = this.tourneyId
      return
    }
    this.getTournaments()
  },
  components: {
    PlayerRatingsTable,
    TournamentQuickAdd,
    TournamentRatingSummaryDialog
  }
}
</script>
