const juniors = [
  {
    rating: 'Unrated',
    headers: [
      { text: '4+', value: 4, align: 'center' }
    ],
    earningRows: [
      {
        finish: 1,
        4: 'B'
      },
      {
        finish: 2,
        4: null
      },
      {
        finish: 3,
        4: null
      },
      {
        finish: 4,
        4: null
      },
      {
        finish: 5,
        4: null
      },
      {
        finish: 7,
        4: null
      },
      {
        finish: 9,
        4: null
      }
    ],
    order: 5
  },
  {
    rating: 'B',
    headers: [
      { text: '4-7', value: 4, align: 'center' },
      { text: '8-11', value: 8, align: 'center' },
      { text: '12-16', value: 12, align: 'center' },
      { text: '16+', value: 16, align: 'center' }
    ],
    earningRows: [
      {
        finish: 1,
        4: 'A',
        8: 'A',
        12: 'A',
        16: 'A'
      },
      {
        finish: 2,
        4: 'B',
        8: 'B',
        12: 'A',
        16: 'A'
      },
      {
        finish: 3,
        4: null,
        8: 'B',
        12: 'B',
        16: 'B'
      },
      {
        finish: 4,
        4: null,
        8: null,
        12: 'B',
        16: 'B'
      },
      {
        finish: 5,
        4: null,
        8: null,
        12: null,
        16: 'B'
      },
      {
        finish: 7,
        4: null,
        8: null,
        12: null,
        16: null
      },
      {
        finish: 9,
        4: null,
        8: null,
        12: null,
        16: null
      }
    ],
    order: 4
  },
  {
    rating: 'A',
    headers: [
      { text: '4-7', value: 4, align: 'center' },
      { text: '8-11', value: 8, align: 'center' },
      { text: '12-16', value: 12, align: 'center' },
      { text: '16+', value: 16, align: 'center' }
    ],
    earningRows: [
      {
        finish: 1,
        4: 'A',
        8: 'A',
        12: 'AA',
        16: 'AA'
      },
      {
        finish: 2,
        4: 'B',
        8: 'A',
        12: 'A',
        16: 'A'
      },
      {
        finish: 3,
        4: null,
        8: 'B',
        12: 'A',
        16: 'A'
      },
      {
        finish: 4,
        4: null,
        8: null,
        12: 'B',
        16: 'A'
      },
      {
        finish: 5,
        4: null,
        8: null,
        12: 'B',
        16: 'B'
      },
      {
        finish: 7,
        4: null,
        8: null,
        12: null,
        16: 'B'
      },
      {
        finish: 9,
        4: null,
        8: null,
        12: null,
        16: null
      }
    ],
    order: 3
  },
  {
    rating: 'AA',
    headers: [
      { text: '4-7', value: 4, align: 'center' },
      { text: '8-11', value: 8, align: 'center' },
      { text: '12-16', value: 12, align: 'center' },
      { text: '16+', value: 16, align: 'center' }
    ],
    earningRows: [
      {
        finish: 1,
        4: 'AA',
        8: 'AA',
        12: 'AAA',
        16: 'AAA'
      },
      {
        finish: 2,
        4: 'A',
        8: 'AA',
        12: 'AA',
        16: 'AA'
      },
      {
        finish: 3,
        4: 'B',
        8: 'A',
        12: 'AA',
        16: 'AA'
      },
      {
        finish: 4,
        4: null,
        8: 'B',
        12: 'A',
        16: 'AA'
      },
      {
        finish: 5,
        4: null,
        8: null,
        12: 'A',
        16: 'A'
      },
      {
        finish: 7,
        4: null,
        8: null,
        12: 'B',
        16: 'A'
      },
      {
        finish: 9,
        4: null,
        8: null,
        12: null,
        16: 'B'
      }
    ],
    order: 2
  },
  {
    rating: 'AAA',
    headers: [
      { text: '4-7', value: 4, align: 'center' },
      { text: '8-11', value: 8, align: 'center' },
      { text: '12-47', value: 12, align: 'center' },
      { text: '48+', value: 48, align: 'center' }
    ],
    earningRows: [
      {
        finish: 1,
        4: 'AA',
        8: 'AAA',
        12: 'AAA',
        48: 'AAA'
      },
      {
        finish: 2,
        4: 'A',
        8: 'AA',
        12: 'AAA',
        48: 'AAA'
      },
      {
        finish: 3,
        4: null,
        8: 'A',
        12: 'AA',
        48: 'AAA'
      },
      {
        finish: 4,
        4: null,
        8: 'A',
        12: 'AA',
        48: 'AA'
      },
      {
        finish: 5,
        4: null,
        8: null,
        12: 'A',
        48: 'AA'
      },
      {
        finish: 7,
        4: null,
        8: null,
        12: null,
        48: 'AA'
      },
      {
        finish: 9,
        4: null,
        8: null,
        12: null,
        48: 'A'
      }
    ],
    order: 1
  }
]

export { juniors }
